@use "sass:map";

@import '~flatpickr/dist/flatpickr.css';

// @import 'catalog';
@import 'components/accordion';
@import 'components/panels';
@import 'components/cards';
@import 'components/slider';
@import 'components/header';
@import 'components/footer';
@import 'components/forms';
@import 'components/richtext';


// #search-modal { }

.callout-homepage {
    .callout-img {
        width: 100%;
        height: 100%;
        background-position: 50% 50%;
        background-size: 100% auto;
        background-repeat: no-repeat;
    }

}

#promo-slider {
    .carousel-control-prev, .carousel-control-next {
        align-items: flex-start;
        padding-top: 90px;
        // filter: drop-shadow(0 0 2px rgba(0,0,0,.5));
    }
    .carousel-indicators {
        margin-bottom: 0;
    }
}

body {
    border-top: 10px solid $top-bar-background-color;

    .alert .notice-message {
        & p:last-child {
            margin-bottom: 0;
        }
    }
}

main {

    aside {
        .block-subnavigation {
            margin-bottom: $spacer;
        }

        .nav-subnav {

            a {
                border-bottom: 1px solid $light;
                &:last-child {
                    border-bottom: none;
                }
            }

            a.active {
                color: $nav-link-active-color;
                font-weight: bold;
            }
        }
    }

    .block-action,
    .block-image,
    .block-embed {
        margin: 20px 0;
        &:first-child {
            margin-top: 0;
        }
    }

    .block-image img {
        width: 100% !important;
        height: auto !important;
    }

    .block-embed {

        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        height: 0;
        overflow: hidden;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }



    #breadcrumbs {
        padding: 10px 0;
        font-size: 12px;
        ol { margin: 0; }
    }

    .breadcrumbs {
        margin-left: 0;
        padding: 10px 0;
        font-size: 12px;
    }



    .quick-search {
        input { font-size: 12px; }
    }

    .table-specs {

        thead td {
            padding-left: 0;
        }

        td {
            padding-left: 26px;
        }

    }

    .text-small {
        // color: $color-muted;
        font-size: 14px;
    }

    .btn-hover-dark:hover {
        background-color: $dark;
        border-color: $dark;

    }

    .share {
        .btn {
            margin-bottom: 10px;
            font-weight: normal;

            &.btn-facebook {
                background-color: #0e1f56;
                color: white;
            }
            &.btn-twitter {
                background-color: #55acee;
                color: white;
            }
            &.btn-linkedin {
                background-color: #0077b5;
                color: white;
            }
            &.btn-email {
                background-color: $lighter;
                color: $dark;
            }

        }

    }

    .hover-dim:hover {

        h3, h4, h5 {
            color: $secondary;
        }

        img {
            opacity: 0.8;
        }
    }

}

.siButtonText {
    font-family: "Prompt", sans-serif !important;
}
