.slider-slide {

    padding: 20px;

    @include media-breakpoint-up(lg) {
        height: 464px;
        padding: 85px;
    }

    background-position: 50% 50%;
    background-size: cover;

    .slider-slide-cta {

        width: 100%;

        @include media-breakpoint-up(lg) {
            max-width: 410px;
        }

        padding: 40px;
        background: rgba(white, 0.78);
        text-align: center;
    }
}