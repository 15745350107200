header {

    background-color: $header-background-color;
    position: relative;
    display: flex;
    flex-direction: column;

    .navbar-toggler {



        position: absolute;
        top: 12px;
        right: 12px;
        z-index: 10;

        &::before {
            font-family: "Font Awesome 5 Pro";
            font-weight: 900;
            font-size: 24px;
            content: "\f00d";
            color: $header-menu-button-color;
        }

        &.collapsed::before {
            content: "\f0c9";
            color: $header-menu-button-color;
        }
    }

    .tel {
        font-size: 24px;
        font-weight: bold;
        color: $dark;
        text-decoration: none;
    }

    #header-top-bar {
        .btn-header {
            background-color: $header-button-bg-color;
            color: $header-button-color;
        }
    }

    #main-navigation {

        #main-navigation-content {

            // push menu to edges (escapes the container)
            margin-left: -20px;
            margin-right: -20px;

            padding: 0 20px;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 4px 4px;
            border-bottom: 1px solid lighten($medium, 25%);

            background-color: $navbar-background-color-mobile;

            @include media-breakpoint-up(lg) {
                // padding: 0 $container-padding-x;
                background-color: $navbar-background-color;
                color: white;
                box-shadow: none;
                border-bottom: none;
            }

            .nav-link {
                padding: 15px 1rem;
                text-transform: uppercase;
                color: $navbar-dropdown-link-color;
                &.show, &:hover {
                    color: $primary;
                    background-color: darken($light, 3%);
                }
                @include media-breakpoint-up(lg) {
                    color: white;
                    &.show, &:hover {
                        color: white;
                        background-color: darken($medium, 15%);
                    }
                }
            }

            ul#main-navigation-left {

                // me-auto my-3 my-lg-0
                margin: 0 $spacer*-1;
                margin-bottom: $spacer;

                @include media-breakpoint-up(lg) {
                    margin-top: 0;
                    margin-bottom: 0;
                    margin-right: auto;
                }


                .dropdown-menu {
                    background-color: $navbar-dropdown-bg;
                    border-left: none;
                    border-right: none;

                    a.dropdown-item {
                        color: $navbar-dropdown-link-color;

                        &:hover {
                            background-color: $navbar-dropdown-link-hover-bg;
                        }
                    }
                }

                .mega-menu-dropdown {
                    // align main navigation with container
                    .nav-link {
                        padding-left: $spacer;
                        &:not(.collapsed) {
                            background-color: darken($medium, 15%);
                        }
                    }
                }

                .mobile-equipment-dropdown {
                    .nav-link {
                        // padding-top: 0;
                    }
                }
            }
        }

        #search-button {
            font-size: 20px;
        }

        #search {
            padding: $spacer;
        }

    }

    #eq-mega-menu {

        margin-left: -$grid-gutter-width;
        margin-right: -$grid-gutter-width;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 4px 4px;
        border-bottom: 1px solid lighten($medium, 30%);

        .mega-menu {
            padding: $spacer;
            background-color: $megamenu-dropdown-bg;
            font-size: $font-size-sm;
            color: $megamenu-dropdown-color;
            h1,h2,h3,h4,h5,h6 {
                color: darken($megamenu-dropdown-color, 10%);
            }
        }
    }
}