.accordion-button {
    // position: relative;
    // display: flex;
    // align-items: center;
    // width: 100%;
    // margin-left: -accordion-button-padding-y;
    padding: $accordion-button-padding-y $accordion-button-padding-x;
    // padding-left: 0;
    // @include font-size($font-size-base);
    // color: $accordion-button-color;
    // text-align: left; // Reset button style
    // background-color: $accordion-button-bg;
    // border: 0;
    // @include border-radius(0);
    // overflow-anchor: none;
    // @include transition($accordion-transition);

    &:not(.collapsed) {
    //   color: $accordion-button-active-color;
    //   background-color: $accordion-button-active-bg;
    //   box-shadow: inset 0 ($accordion-border-width * -1) 0 $accordion-border-color;

      &::after {
        background-image: unset;
        transform: unset;
      }

      &::before {
        background-image: escape-svg($accordion-button-active-icon);
        transform: $accordion-icon-transform;
      }
    }

    // Accordion icon
    &::after {
        flex-shrink: unset;
        width: unset;
        height: unset;
        margin-left: unset;
        content: unset;
        background-image: unset;
        background-repeat: unset;
        background-size: $accordion-icon-width;
        // @include transition($accordion-icon-transition);
    }

    &::before {
        flex-shrink: 0;
        width: $accordion-icon-width;
        height: $accordion-icon-width;
        // margin-left: -30px;
        margin-right: 10px;
        content: "";
        background-image: escape-svg($accordion-button-icon);
        background-repeat: no-repeat;
        background-size: $accordion-icon-width;
        @include transition($accordion-icon-transition);
    }
}