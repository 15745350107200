// theme colors
$primary: #FAB026;
$secondary: #DD970C;

@font-face {
    font-family: 'JCBEuro';
    src: url('@/wesgroup/fonts/jcber-webfont.woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'JCBEuro-Bold-Condensed';
    src: url('@/wesgroup/fonts/jcbebc-webfont.woff');
    font-weight: normal;
    font-style: normal;
}

$font-family-sans-serif: 'JCBEuro','Gill Sans', Arial, Helvetica, 'sans-serif';
$headings-font-family: 'JCBEuro-Bold-Condensed','Gill Sans Bold', Arial, Helvetica, 'sans-serif';


@import '../bootstrap-variables';
@import 'bootstrap/scss/bootstrap';

$top-bar-background-color: black;

$header-background-color: $primary;
$header-button-bg-color: white;
$header-button-color: black;
$header-menu-button-color: $dark;

$navbar-background-color: black;
$navbar-background-color-mobile: black;
$navbar-dropdown-bg: #1A1A1A;
$navbar-dropdown-link-color: white;
$navbar-dropdown-link-hover-bg: $primary;

$megamenu-dropdown-bg: #1A1A1A;
$megamenu-dropdown-color: white;

$nav-link-active-color: $secondary;

@import '../site.scss';