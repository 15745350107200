@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

// --- OPTIONS ---

// $enable-caret:                true !default;
// $enable-rounded:              true !default;
// $enable-shadows:              false !default;
// $enable-gradients:            false !default;
// $enable-transitions:          true !default;
// $enable-reduced-motion:       true !default;
// $enable-smooth-scroll:        true !default;
// $enable-grid-classes:         true !default;
// $enable-button-pointers:      true !default;
// $enable-rfs:                  true !default;
// $enable-validation-icons:     true !default;
$enable-negative-margins:     true;
// $enable-deprecation-messages: true !default;
// $enable-important-utilities:  true !default;

// --- COLORS ---

$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;

$blue:    #0d6efd;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #d63384;
$red:     #dc3545;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #198754;
$teal:    #20c997;
$cyan:    #0dcaf0;

// --- THEME COLORS ---

$primary: #E00000 !default; // override in site themes
$secondary: #A71D21 !default; // override in site themes
// ---
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
// ---
$lighter: #e5e5e5;          // for megamenu and panels
$light: #dbdbdb;            // for background
$medium: #707070;           // for main text, main navigation
$dark: #333333;             // for subfooter, headings
// ---
$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "lighter":    $lighter,
  "light":      $light,
  "medium":     $medium,
  "dark":       $dark
);

// --- SPACING ---

$spacer: 1.25rem;               // -> 20px (bootstrap default is 1rem or 16px)
$spacers: (
    0: 0,                       // -> 0
    1: calc($spacer / 4),             // -> 5px
    2: calc($spacer / 2),             // -> 10px
    3: $spacer,                 // -> 20px
    4: $spacer * 1.5,           // -> 30px
    5: $spacer * 3,             // -> 60px
);

// --- GRIDS ---

$grid-columns: 12;
$grid-gutter-width: $spacer;    // -> 20px
$grid-row-columns: 6;

// $gutters: $spacers !default;

// Container padding
$container-padding-x: 40px; // --> 20px (was $grid-gutter-width / 2)


// --- COMPONENTS ---

// Components
//
// Define common padding and border radius sizes and more.

// scss-docs-start border-variables
$border-width:                1px !default;
$border-widths: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px
) !default;

$border-color:                $gray-300 !default;
// scss-docs-end border-variables

// scss-docs-start border-radius-variables
$border-radius:               .25rem !default;
$border-radius-sm:            .2rem !default;
$border-radius-lg:            .3rem !default;
$border-radius-pill:          50rem !default;
// scss-docs-end border-radius-variables

// scss-docs-start box-shadow-variables
$box-shadow:                  0 .5rem 1rem rgba($black, .15) !default;
$box-shadow-sm:               0 .125rem .25rem rgba($black, .075) !default;
$box-shadow-lg:               0 1rem 3rem rgba($black, .175) !default;
$box-shadow-inset:            inset 0 1px 2px rgba($black, .075) !default;
// scss-docs-end box-shadow-variables

$component-active-color:      $white !default;
$component-active-bg:         $primary !default;

// scss-docs-start caret-variables
$caret-width:                 .3em !default;
$caret-vertical-align:        $caret-width * .85 !default;
$caret-spacing:               $caret-width * .85 !default;
// scss-docs-end caret-variables

$transition-base:             all .2s ease-in-out !default;
$transition-fade:             opacity .15s linear !default;
// scss-docs-start collapse-transition
$transition-collapse:         height .35s ease !default;
// scss-docs-end collapse-transition

// stylelint-disable function-disallowed-list
// scss-docs-start aspect-ratios
$aspect-ratios: (
  "1x1": 100%,
  "4x3": calc(3 / 4 * 100%),
  "3x2": calc(2 / 3 * 100%),
  "16x9": calc(9 / 16 * 100%),
  "21x9": calc(9 / 21 * 100%)
) !default;
// scss-docs-end aspect-ratios
// stylelint-enable



// --- BODY ---
$body-bg: $light;
$body-color: $medium;
// $body-text-align: null;

// --- LINKS ---
$link-color: $primary !default;
$link-hover-color: $secondary !default;
$link-decoration: none; // underline;
$link-hover-decoration: null;
// $link-shade-percentage: 20%;
// $link-hover-color: shift-color($link-color, $link-shade-percentage);
// $stretched-link-pseudo-element:           after !default;
// $stretched-link-z-index:                  1 !default;

// --- PARAGRAPHS ---
$paragraph-margin-bottom: 1.0625rem; // -> 17px

// --- FONTS
$font-family-sans-serif: 'Open Sans', sans-serif !default;

$font-size-base: 1.0625rem;     // -> 17px
$font-size-sm: 0.9375rem;       // -> 15px
$font-size-lg: 1.25rem;         // -> 20px
$font-size-xl: 1.5rem;          // -> 24px

// $line-height-base: 1.5 !default;
// $line-height-sm: 1.25 !default;
// $line-height-lg: 2 !default;

$h1-font-size: 1rem * 2.25;     // -> 40px --> 36
$h2-font-size: 1rem * 1.75;     // -> 32px --> 28
$h3-font-size: 1rem * 1.5;      // -> 28px --> 24
$h4-font-size: 1rem * 1.25;     // -> 24px --> 20
$h5-font-size: 1rem * 1.125;    // -> 20px --> 18
$h6-font-size: $font-size-base; // -> 17px

// fs-1 -> 40px
// fs-2 -> 32px
// fs-3 -> 28px
// fs-4 -> 24px
// fs-5 -> 20px
// fs-6 -> 17px

// $headings-margin-bottom:      $spacer / 2 !default;
// $headings-font-family:        null !default;
// $headings-font-style:         null !default;
$headings-font-weight: 600;
// $headings-line-height:        1.2 !default;
$headings-color: $dark;

$lead-font-size: $font-size-lg; // 20px;
$lead-font-weight: 400;

$small-font-size:             .875em !default;
$text-muted:                  $gray-600 !default;


$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-bolder: bolder;

$font-weight-base:            $font-weight-normal !default;

$line-height-base:            1.5 !default;
$line-height-sm:              1.25 !default;
$line-height-lg:              2 !default;


// Buttons + Forms





// regular buttons ARE large buttons
$input-btn-padding-y: 0.4375rem; // or 7px;
$input-btn-padding-x: 1.25rem; // or 20px (.75rem default);
$input-btn-font-family:       null !default;
$input-btn-font-size: $font-size-lg;
$input-btn-line-height:       $line-height-base !default;

$input-btn-focus-width:         .25rem !default;
$input-btn-focus-color-opacity: .25 !default;
$input-btn-focus-color:         rgba($component-active-bg, $input-btn-focus-color-opacity) !default;
$input-btn-focus-blur:          0 !default;
$input-btn-focus-box-shadow:    0 0 $input-btn-focus-blur $input-btn-focus-width $input-btn-focus-color !default;


$input-btn-padding-y-sm: .3125rem; // or 5px
$input-btn-padding-x-sm: .75rem;
$input-btn-font-size-sm: $font-size-sm;

$input-btn-padding-y-lg: .75rem;
$input-btn-padding-x-lg: 1.5rem;
$input-btn-font-size-lg: $font-size-xl;
$input-btn-border-width:      $border-width !default;



// Buttons


// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

// scss-docs-start btn-variables
$btn-padding-y:               $input-btn-padding-y !default;
$btn-padding-x:               $input-btn-padding-x !default;
$btn-font-family:             $input-btn-font-family !default;
$btn-font-size:               $input-btn-font-size !default;
$btn-line-height:             $input-btn-line-height !default;
$btn-white-space:             null !default; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-sm:            $input-btn-padding-y-sm !default;
$btn-padding-x-sm:            $input-btn-padding-x-sm !default;
$btn-font-size-sm:            $input-btn-font-size-sm !default;

$btn-padding-y-lg:            $input-btn-padding-y-lg !default;
$btn-padding-x-lg:            $input-btn-padding-x-lg !default;
$btn-font-size-lg:            $input-btn-font-size-lg !default;

$btn-border-width:            $input-btn-border-width !default;

$btn-font-weight: $font-weight-bold;
$btn-box-shadow:              inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075) !default;
$btn-focus-width:             $input-btn-focus-width !default;
$btn-focus-box-shadow:        $input-btn-focus-box-shadow !default;
$btn-disabled-opacity:        .65 !default;
$btn-active-box-shadow:       inset 0 3px 5px rgba($black, .125) !default;

$btn-link-color:              $link-color !default;
$btn-link-hover-color:        $link-hover-color !default;
$btn-link-disabled-color:     $gray-600 !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:           $border-radius !default;
$btn-border-radius-sm:        $border-radius-sm !default;
$btn-border-radius-lg:        $border-radius-lg !default;

$btn-transition:              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

$btn-hover-bg-shade-amount:       15% !default;
$btn-hover-bg-tint-amount:        15% !default;
$btn-hover-border-shade-amount:   20% !default;
$btn-hover-border-tint-amount:    10% !default;
$btn-active-bg-shade-amount:      20% !default;
$btn-active-bg-tint-amount:       20% !default;
$btn-active-border-shade-amount:  25% !default;
$btn-active-border-tint-amount:   10% !default;
// scss-docs-end btn-variables






// Forms

// scss-docs-start form-text-variables
$form-text-margin-top:                  .25rem !default;
$form-text-font-size:                   $small-font-size !default;
$form-text-font-style:                  null !default;
$form-text-font-weight:                 null !default;
$form-text-color:                       $text-muted !default;
// scss-docs-end form-text-variables

// scss-docs-start form-label-variables
$form-label-margin-bottom:              .5rem !default;
$form-label-font-size:                  null !default;
$form-label-font-style:                 null !default;
$form-label-font-weight:                null !default;
$form-label-color:                      null !default;
// scss-docs-end form-label-variables

// scss-docs-start form-input-variables
$input-padding-y:                       $input-btn-padding-y !default;
$input-padding-x:                       $input-btn-padding-x !default;
$input-font-family:                     $input-btn-font-family !default;
$input-font-size:                       $input-btn-font-size !default;
$input-font-weight:                     $font-weight-base !default;
$input-line-height:                     $input-btn-line-height !default;

$input-padding-y-sm:                    $input-btn-padding-y-sm !default;
$input-padding-x-sm:                    $input-btn-padding-x-sm !default;
$input-font-size-sm:                    $input-btn-font-size-sm !default;

$input-padding-y-lg:                    $input-btn-padding-y-lg !default;
$input-padding-x-lg:                    $input-btn-padding-x-lg !default;
$input-font-size-lg:                    $input-btn-font-size-lg !default;

$input-bg:                              $white !default;
$input-disabled-bg:                     $gray-200 !default;
$input-disabled-border-color:           null !default;

$input-color:                           $body-color !default;
$input-border-color:                    $gray-400 !default;
$input-border-width:                    $input-btn-border-width !default;
$input-box-shadow:                      $box-shadow-inset !default;

$input-border-radius:                   $border-radius !default;
$input-border-radius-sm:                $border-radius-sm !default;
$input-border-radius-lg:                $border-radius-lg !default;

$input-focus-bg:                        $input-bg !default;
$input-focus-border-color:              tint-color($component-active-bg, 50%) !default;
$input-focus-color:                     $input-color !default;
$input-focus-width:                     $input-btn-focus-width !default;
$input-focus-box-shadow:                $input-btn-focus-box-shadow !default;

$input-placeholder-color:               $gray-600 !default;
$input-plaintext-color:                 $body-color !default;

$input-height-border:                   $input-border-width * 2 !default;

$input-height-inner:                    add($input-line-height * 1em, $input-padding-y * 2) !default;
$input-height-inner-half:               add($input-line-height * .5em, $input-padding-y) !default;
$input-height-inner-quarter:            add($input-line-height * .25em, calc($input-padding-y / 2)) !default;

$input-height:                          add($input-line-height * 1em, add($input-padding-y * 2, $input-height-border, false)) !default;
$input-height-sm:                       add($input-line-height * 1em, add($input-padding-y-sm * 2, $input-height-border, false)) !default;
$input-height-lg:                       add($input-line-height * 1em, add($input-padding-y-lg * 2, $input-height-border, false)) !default;

$input-transition:                      border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;
// scss-docs-end form-input-variables



// // Navs

// // scss-docs-start nav-variables
// $nav-link-padding-y:                .5rem !default;
// $nav-link-padding-x:                1rem !default;
$nav-link-font-size: 18px;
$nav-link-font-weight: $font-weight-normal;
// $nav-link-color:                    $link-color !default;
// $nav-link-hover-color:              $link-hover-color !default;
// $nav-link-transition:               color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out !default;
// $nav-link-disabled-color:           $gray-600 !default;

// $nav-tabs-border-color:             $gray-300 !default;
// $nav-tabs-border-width:             $border-width !default;
// $nav-tabs-border-radius:            $border-radius !default;
// $nav-tabs-link-hover-border-color:  $gray-200 $gray-200 $nav-tabs-border-color !default;
// $nav-tabs-link-active-color:        $gray-700 !default;
// $nav-tabs-link-active-bg:           $body-bg !default;
// $nav-tabs-link-active-border-color: $gray-300 $gray-300 $nav-tabs-link-active-bg !default;

// $nav-pills-border-radius:           $border-radius !default;
// $nav-pills-link-active-color:       $component-active-color !default;
// $nav-pills-link-active-bg:          $component-active-bg !default;
// // scss-docs-end nav-variables




// // Navbar

// // scss-docs-start navbar-variables
$navbar-padding-y: 0; // $spacer / 2 -> 10px
// $navbar-padding-x: null;

$navbar-nav-link-padding-x: 1rem;

// $navbar-brand-font-size:            $font-size-lg !default;
// // Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
// $nav-link-height:                   $font-size-base * $line-height-base + $nav-link-padding-y * 2 !default;
// $navbar-brand-height:               $navbar-brand-font-size * $line-height-base !default;
// $navbar-brand-padding-y:            ($nav-link-height - $navbar-brand-height) / 2 !default;
// $navbar-brand-margin-end:           1rem !default;

// $navbar-toggler-padding-y:          .25rem !default;
// $navbar-toggler-padding-x:          .75rem !default;
// $navbar-toggler-font-size:          $font-size-lg !default;
// $navbar-toggler-border-radius:      $btn-border-radius !default;
// $navbar-toggler-focus-width:        $btn-focus-width !default;
// $navbar-toggler-transition:         box-shadow .15s ease-in-out !default;
// // scss-docs-end navbar-variables

// // scss-docs-start navbar-theme-variables
$navbar-dark-color: white;
// $navbar-dark-hover-color:           rgba($white, .75) !default;
// $navbar-dark-active-color:          $white !default;
// $navbar-dark-disabled-color:        rgba($white, .25) !default;
// $navbar-dark-toggler-icon-bg:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
// $navbar-dark-toggler-border-color:  rgba($white, .1) !default;

// $navbar-light-color:                rgba($black, .55) !default;
// $navbar-light-hover-color:          rgba($black, .7) !default;
// $navbar-light-active-color:         rgba($black, .9) !default;
// $navbar-light-disabled-color:       rgba($black, .3) !default;
// $navbar-light-toggler-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
// $navbar-light-toggler-border-color: rgba($black, .1) !default;

// $navbar-light-brand-color:                $navbar-light-active-color !default;
// $navbar-light-brand-hover-color:          $navbar-light-active-color !default;
// $navbar-dark-brand-color:                 $navbar-dark-active-color !default;
// $navbar-dark-brand-hover-color:           $navbar-dark-active-color !default;
// // scss-docs-end navbar-theme-variables


// // Dropdowns
// //
// // Dropdown menu container and contents.

// // scss-docs-start dropdown-variables
// $dropdown-min-width:                10rem !default;
// $dropdown-padding-x:                0 !default;
// $dropdown-padding-y:                .5rem !default;
// $dropdown-spacer:                   .125rem !default;
// $dropdown-font-size:                $font-size-base !default;
// $dropdown-color:                    $body-color !default;
$dropdown-bg: $light;
// $dropdown-border-color:             rgba($black, .15) !default;
$dropdown-border-radius: 0;           // $border-radius !default;
// $dropdown-border-width:             $border-width !default;
// $dropdown-inner-border-radius:      subtract($dropdown-border-radius, $dropdown-border-width) !default;
// $dropdown-divider-bg:               $dropdown-border-color !default;
// $dropdown-divider-margin-y:         $spacer / 2 !default;
// $dropdown-box-shadow:               $box-shadow !default;

$dropdown-link-color: $dark;
$dropdown-link-hover-color: $primary;         // shade-color($gray-900, 10%) !default;
$dropdown-link-hover-bg: darken($light, 2%); // $lighter;

$dropdown-link-active-color: $secondary;        // $component-active-color !default;
$dropdown-link-active-bg: $lighter;         // $secondary; //$component-active-bg !default;

// $dropdown-link-disabled-color:      $gray-500 !default;

// $dropdown-item-padding-y:           $spacer / 4 !default;
// $dropdown-item-padding-x:           $spacer !default;

// $dropdown-header-color:             $gray-600 !default;
// $dropdown-header-padding:           $dropdown-padding-y $dropdown-item-padding-x !default;
// // scss-docs-end dropdown-variables

// // scss-docs-start dropdown-dark-variables
// $dropdown-dark-color:               $gray-300 !default;
// $dropdown-dark-bg:                  $gray-800 !default;
// $dropdown-dark-border-color:        $dropdown-border-color !default;
// $dropdown-dark-divider-bg:          $dropdown-divider-bg !default;
// $dropdown-dark-box-shadow:          null !default;
// $dropdown-dark-link-color:          $dropdown-dark-color !default;
// $dropdown-dark-link-hover-color:    $white !default;
// $dropdown-dark-link-hover-bg:       rgba($white, .15) !default;
// $dropdown-dark-link-active-color:   $dropdown-link-active-color !default;
// $dropdown-dark-link-active-bg:      $dropdown-link-active-bg !default;
// $dropdown-dark-link-disabled-color: $gray-500 !default;
// $dropdown-dark-header-color:        $gray-500 !default;
// // scss-docs-end dropdown-dark-variables




// Cards

// $card-spacer-y:                     $spacer;
// $card-spacer-x:                     $spacer;
// $card-title-spacer-y:               $spacer / 2;
// $card-border-width:                 $border-width;
// $card-border-radius:                $border-radius;
// $card-border-color:                 rgba($black, .125);
// $card-inner-border-radius:          subtract($card-border-radius, $card-border-width);
// $card-cap-padding-y:                $card-spacer-y / 2;
// $card-cap-padding-x:                $card-spacer-x;
// $card-cap-bg:                       rgba($black, .03);
// $card-cap-color:                    null;
// $card-height:                       null;
// $card-color:                        null;
// $card-bg:                           $white;
// $card-img-overlay-padding:          $spacer;
// $card-group-margin:                 $grid-gutter-width / 2;




// Accordion


$accordion-padding-y:                     1rem;
$accordion-padding-x:                     1.25rem;
$accordion-color:                         $dark;
$accordion-bg:                            white;
// $accordion-border-width:                  $border-width !default;
$accordion-border-color:                  rgba($black, .125) !default;
// $accordion-border-radius:                 $border-radius !default;
// $accordion-inner-border-radius:           subtract($accordion-border-radius, $accordion-border-width) !default;

$accordion-body-padding-y:                $accordion-padding-y !default;
$accordion-body-padding-x:                $accordion-padding-x !default;

$accordion-button-padding-y:              $accordion-padding-y !default;
$accordion-button-padding-x:              $accordion-padding-x !default;
$accordion-button-color:                  $accordion-color !default;
$accordion-button-bg:                     $accordion-bg !default;
// $accordion-transition:                    $btn-transition, border-radius .15s ease !default;
$accordion-button-active-bg:              tint-color($component-active-bg, 90%) !default;
$accordion-button-active-color:           $dark;

$accordion-button-focus-border-color:     $input-focus-border-color !default;
$accordion-button-focus-box-shadow:       $btn-focus-box-shadow !default;

$accordion-icon-width:                    1.25rem !default;
$accordion-icon-color:                    $primary; // $accordion-color !default;
$accordion-icon-active-color:             $primary; // $accordion-button-active-color !default;
$accordion-icon-transition:               transform .2s ease-in-out !default;
$accordion-icon-transform:                rotate(-180deg) !default;

$accordion-button-icon:         url("data:image/svg+xml,<svg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='plus-circle' class='svg-inline--fa fa-plus-circle fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='currentColor' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm144 276c0 6.6-5.4 12-12 12h-92v92c0 6.6-5.4 12-12 12h-56c-6.6 0-12-5.4-12-12v-92h-92c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h92v-92c0-6.6 5.4-12 12-12h56c6.6 0 12 5.4 12 12v92h92c6.6 0 12 5.4 12 12v56z'></path></svg>");
$accordion-button-active-icon:  url("data:image/svg+xml,<svg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='minus-circle' class='svg-inline--fa fa-minus-circle fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='currentColor' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zM124 296c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h264c6.6 0 12 5.4 12 12v56c0 6.6-5.4 12-12 12H124z'></path></svg>");



