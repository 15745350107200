.card-grid .card {
    height: 100%;
}

.card {

    height: 100%;

    .card-img-top {
        width: 100% !important;
        height: auto !important;

        @include media-breakpoint-up(lg) {
            max-height: 202px;
        }
    }

    .card-title {
        font-size: 17px;
    }

    &:hover {
        .card-title {
            color: $link-hover-color;
        }
        img {
            opacity: 0.8;
        }
    }

    .promo {
        background-color: $secondary;
        color: white;
        padding: 4px 12px;
        position: absolute;
        right: 0;
        top: 20px;
        font-size: 14px;
    }

}

.card-category, .card-location {

    height: 100%;
    padding-bottom: 41.5px;

    .card-label {
        position: absolute;
        bottom: 0;
        width: 100%;
        margin-bottom: 0;
        padding: 8px 20px;
        background-color: rgba($light, 0.9);
        color: $dark;
        font-size: 17px;
        font-weight: bold;
    }
}

.card-model, .card-item {

    height: 100%;

    &:hover {
        .card-title {
            // color: $primary !important;
        }
    }

    .card-body {
        // background-color: $lighter;
        .card-title {
            display: block;
            margin: -20px -20px 10px -20px !important;
            padding: 5px 20px;
            // font-size: 20px;
            background: $dark;
            color: white;
        }

        .card-text {
            margin-bottom: -10px;
            font-size: 12px;
        }

    }

}

.card-location {
    .card-title {
        font-size: 18px;
        font-weight: bold;
        color: $dark;
    }
}

.card-promo {

    // margin: 20px 0;

    .card-title {
        font-size: 18px;
        font-weight: bold;
        color: $dark;
    }
    .card-text {
        margin-top: 20px;
        font-size: 16px;
        .expiry {
            font-size: 14px;
            font-weight: bold;
        }
    }

}


.block-actions {
    ul {
        list-style: none;
        display: flex;
        padding: 0;
        margin: 0 -5px;

        li {
            padding: 0 5px;
        }
    }
}