
.richtext-image {

    padding-bottom: 20px;

    &.left {
        width: 50%;
        height: auto;
        float: left;
        padding-right: 20px;
        // margin-right: 20px;
    }

    &.right {
        width: 50%;
        height: auto;
        float: right;
        // margin-left: 20px;
        padding-left: 20px;

    }

    &.full-width {
        width: 100%;
        height: auto;
    }

}