form.pardot-form {

    .date-field {
        background-color: #FFFFFF;
    }

    // .date-widget {
    //     width: 60%;
    //     display: flex;
    //     margin: 0 -10px;
    //     select {
    //         margin: 0 10px;
    //     }
    // }

}