/* --- PANEL --- */

.panel {

    background-color: $lighter;
    padding: 20px;

    &.panel-img {
        padding: 20px 0;
    }

    .panel-text {
        // padding: 20px;
    }

}
