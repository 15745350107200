footer#main-footer {

    border-bottom: 10px solid $primary;

    #main-footer-top {
        padding-top: map.get($spacers, 5);

        background-color: black;
        background-image: url('~/public/img/footer.webp');
        color: white;

        .footer-logo {
            width: 100%;
            @include media-breakpoint-up(lg) {
                max-width: 350px;
            }
            @include media-breakpoint-up(xl) {
                width: auto;
                max-width: auto;
                max-height: 170px;
            }
        }

        h4 {
            color: white;
        }

        .location-card {
            h4 a {
                text-decoration: none;
            }
        }

    }
    #main-footer-bottom {
        font-size: $h5-font-size; // -> 20px
    }
}